<template>
  <page-layout>
    <div class="wrapper dashboard">
      <div class="title-wrap">
        <h3 class="page-titles">
          Welcome {{ this.$store.state.USER.user.firstName }}
        </h3>
        <p>Great to have you back! Feel free to explore, or follow these steps and create your Team.</p>

        <div class="profile">
          <the-profile class="print:hidden" />
        </div>
      </div>

      <div class="rounded-3xl bg-white dashboard-card p-12">
        <div class="flex p-8 mobile-flex justify-between">
          <div class="flex w-1/2">
            <div class="flex flex-col items-center">
              <h5 class="tab-view-icon px-3 py-1">
                <span>1</span>
              </h5>
              <div class="vl" />
              <h5 class="tab-view-icon px-3 py-1">
                <span>2</span>
              </h5> <div class="vl" />
              <h5 class="tab-view-icon px-3 py-1">
                <span>3</span>
              </h5> <div class="vl" />
              <h5 class="tab-view-icon px-3 py-1">
                <span>4</span>
              </h5> <div class="vl" />
              <h5 class="tab-view-icon px-3 py-1">
                <span>5</span>
              </h5> <div class="vl" />
              <h5 class="tab-view-icon px-3 py-1">
                <span>6</span>
              </h5> <div class="vl" />
              <h5 class="tab-view-icon px-3 py-1">
                <span>7</span>
              </h5> <div class="vl" />
              <h5 class="tab-view-icon px-3 py-1">
                <span>8</span>
              </h5> <div class="vl" />
              <h5 class="tab-view-icon px-3 py-1">
                <span>9</span>
              </h5> <div class="vl" />
              <h5 class="tab-view-icon px-3 py-1">
                <span>10</span>
              </h5>
            </div>
            <div class="flex flex-col">
              <div class="dashboard-card-steps lite-gray md:mt-5 lg:mt-5">
                <h3 class="font-semibold">
                  Introduction
                </h3>
                <p>
                  Team leader share and team read "About- and Process Cognitive Team Profiling" under <a
                    class="underline underline-offset-8"
                    :href="`${$APP_FRONTEND_URL}/demo-videos`"
                    target="_blank"
                  > Resources </a> to the left
                </p>
              </div>
              <div class="dashboard-card-steps mx-2 sm:mt-2 md:mt-11 lg:mt-11 lite-gray">
                <h3 class="font-semibold">
                  Define targets and scope
                </h3>
                <p>
                  Team defines scope and ambition with the exercise
                </p>
              </div>
              <div class="dashboard-card-steps  mx-2 sm:mt-2 md:mt-12 lg:mt-11 lite-gray">
                <h3 class="font-semibold">
                  Create and distribute test links
                </h3>
                <p>
                  Team leader create and copy link and instructions under <a
                    class="underline underline-offset-8"
                    :href="`${$APP_FRONTEND_URL}/teams/0`"
                    target="_blank"
                  > Team Brain </a> to the left
                </p>
              </div>
              <div class="dashboard-card-steps  mx-2 sm:mt-2 md:mt-12 lg:mt-11   lite-gray">
                <h3 class="font-semibold">
                  Perform test
                </h3>
                <p>
                  On mobile by carefully following the instructions
                </p>
              </div>
              <div class="dashboard-card-steps  mx-2 sm:mt-2 md:mt-12 lg:mt-11   lite-gray">
                <h3 class="font-semibold">
                  Training
                </h3>
                <p>
                  Team understand cognitive profile and report by watching videos under <a
                    class="underline underline-offset-8"
                    :href="`${$APP_FRONTEND_URL}/demo-videos`"
                    target="_blank"
                  > Resources </a>
                </p>
              </div>
              <div class="dashboard-card-steps  mx-2 sm:mt-2 md:mt-12 lg:mt-11   lite-gray">
                <h3 class="font-semibold">
                  Individual Cognitive Profile
                </h3>
                <p>
                  Individuals and Team leader validate and discuss individual profile
                </p>
              </div>
              <div class="dashboard-card-steps  mx-2 sm:mt-2 md:mt-12 lg:mt-11   lite-gray">
                <h3 class="font-semibold">
                  Team Cognitive Profile
                </h3>
                <p>
                  Team discuss and validate Team profile
                </p>
              </div>
              <div class="dashboard-card-steps  mx-2 sm:mt-2 md:mt-12 lg:mt-11   lite-gray">
                <h3 class="font-semibold">
                  Action plan
                </h3>
                <p>
                  Map defined targets (SWOT) with findings and create a pragmatic action plan
                </p>
              </div>
              <div class="dashboard-card-steps  mx-2 sm:mt-2 md:mt-12 lg:mt-11   lite-gray">
                <h3 class="font-semibold">
                  Implementation
                </h3>
                <p>
                  Follow up and adjust the plan
                </p>
              </div>
              <div class="dashboard-card-steps  mx-2 sm:mt-2 md:mt-12 lg:mt-11   lite-gray">
                <h3 class="font-semibold">
                  Next step
                </h3>
                <p>
                  Plan for continuous development on individual and team level
                </p>
              </div>
            </div>
          </div>
          <div class="illustration flex flex-col items-center w-2/5">
            <img
              src="@/assets/capacio/dashboard/dashboard-img.svg"
            >
            <router-link
              class="btn-primary dashboard-create mt-5"
              :to="{ name: 'TeamBrain', params: { page: this.$route.params.page || 0 } }"
            >
              Manage teams
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </page-layout>
</template>

<script>
import PageLayout from '@/layouts/PageLayout.vue'
// import LinkCard from '../components/LinkCard.vue'
import TheProfile from '@/components/TheProfile.vue'
export default {
  components: {
    // LinkCard,
    PageLayout,
    TheProfile
  }
}
</script>

<style lang="scss">
@import "../dashboard.scss";
</style>
